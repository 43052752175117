

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.progress-bar {
    width: 350px;
    height: 35px;
    border-radius: 50px;
    border: 2px solid #666;
    margin-bottom: 40px;
}

.range {
    background: limegreen;
    height: 100%;
    border-radius: inherit;
    transition: width .3s ease-in;
}

.toggle-buttons button {
    margin: 0 10px;
    padding: 7px 20px;
    border-radius: 5px;
    outline: 0;
    cursor: pointer;
}

.toggle-buttons button:hover {
    color: white;
    background: #666;
}